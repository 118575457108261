<template>
  <div data-app>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body pt-3 pb-0">
        <div class="row">
          <div
            class="col-md-3"
            v-if="Number(currentUserPersonalInfo.perfil_id) === 1"
          >
            <v-autocomplete
              v-model="mandante"
              :items="mandantes"
              @change="getCampaigns"
              label="Mandante"
              dense
            ></v-autocomplete>
          </div>
          <div class="col-md-3">
            <v-autocomplete
              v-model="estado"
              :items="estados"
              @change="filterCampaigns"
              item-key="estadoID"
              item-value="estadoID"
              item-text="estadoDescripcion"
              label="Estado"
              dense
            ></v-autocomplete>
          </div>
          <div class="col-md-3">
            <v-autocomplete
              v-model="campaign"
              :items="campaignsFiltered"
              label="Campaña"
              item-key="campaignID"
              item-value="campaignID"
              item-text="nombre"
              dense
            ></v-autocomplete>
          </div>
          <div class="col-md-3">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-on:click="showCampaign()"
            >
              Filtrar
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <v-row v-if="dialogShowCampaign">
      <CampaignDetail
        :campaignData="campaignData"
        :jobs="campaignData.tareas"
      ></CampaignDetail>
      <v-col cols="12" v-if="dialogShowCampaign">
        <div class="card card-custom card-stretch">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Campos y Tareas</h3>
            </div>
          </div>
          <div class="card-body p-5 pb-7">
            <b-tabs content-class="mt-3">
              <b-tab title="TAREAS" active>
                <v-row>
                  <v-col
                    cols="4"
                    class="d-flex align-items-center font-size-110"
                  >
                    <span class="font-weight-bolder">Fecha Inicio:</span>
                    <span class="ml-1">{{ campaignData.fechaInicio }}</span>
                  </v-col>
                  <v-col cols="8">
                    <v-btn
                      color="primary float-right"
                      dark
                      class="mb-2"
                      v-on:click="
                        downloadExcelFile(
                          'tareas',
                          'task_table',
                          headersTasks.length
                        )
                      "
                    >
                      Exportar
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-data-table
                      :headers="headersTasks"
                      :items="tasks"
                      class="table-striped elevation-1 table-fixed"
                      hide-default-footer
                      disable-pagination
                      id="task_table"
                    >
                      <template v-slot:[`item.tareas`]="{ item }">
                        <span
                          class="cursor-pointer"
                          @click="getTasksFiltered(-1, item.jobberID, item)"
                        >
                          {{ item.tareas }}
                        </span>
                      </template>
                      <template
                        v-for="(day, iDayIndex) in this.totalTasksDays"
                        v-slot:[`item.dia_${iDayIndex}`]="{ item }"
                      >
                        <span
                          v-if="day > 0"
                          class="cursor-pointer"
                          @click="getTasksFiltered(iDayIndex, item.jobberID)"
                          :key="iDayIndex"
                        >
                          {{
                            item["dia_" + iDayIndex]
                              ? item["dia_" + iDayIndex]["cantidad"]
                              : ""
                          }}
                        </span>
                      </template>
                      <template slot="body.append">
                        <tr class="font-weight-bolder">
                          <td>Total</td>
                          <td class="text-center">
                            <span
                              class="cursor-pointer"
                              @click="getTasksFiltered()"
                            >
                              {{ campaignData.cantidadTareasRealizadas }}/{{
                                campaignData.cantidadTareasAsignadas
                              }}
                            </span>
                          </td>
                          <td
                            class="text-center"
                            v-for="(total, iTotalIndex) in this.totalTasksDays"
                            :key="iTotalIndex"
                          >
                            <span
                              class="cursor-pointer"
                              @click="getTasksFiltered(iTotalIndex)"
                            >
                              {{ total }}
                            </span>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </b-tab>
              <b-tab title="CAMPOS">
                <v-row>
                  <v-col
                    cols="4"
                    class="d-flex align-items-center font-size-110"
                  >
                    <span class="font-weight-bolder">Fecha Inicio:</span>
                    <span class="ml-1">
                      {{ campaignData.fechaInicio }}
                    </span>
                  </v-col>
                  <v-col cols="8">
                    <v-btn
                      color="primary float-right"
                      dark
                      class="mb-2"
                      v-on:click="
                        downloadExcelFile(
                          'campos',
                          'field_table',
                          headersFields.length
                        )
                      "
                    >
                      Exportar
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-data-table
                      :headers="headersFields"
                      :items="fields"
                      class="table-striped elevation-1 table-fixed"
                      hide-default-footer
                      disable-pagination
                      id="field_table"
                    >
                      <template v-slot:[`item.titulo`]="{ item }">
                        <span
                          :class="
                            !item.isOption ? 'font-weight-bolder' : 'ident'
                          "
                        >
                          {{ item.titulo }}
                        </span>
                      </template>
                      <template v-slot:[`item.tareas`]="{ item }">
                        <span
                          class="cursor-pointer"
                          @click="getTasksFiltered(-1, null, item)"
                        >
                          {{ item.totalTareasRealizadas }}
                        </span>
                      </template>
                      <template
                        v-for="(day, iDayIndex) in this.totalFieldsDays"
                        v-slot:[`item.dia_${iDayIndex}`]="{ item }"
                      >
                        <span
                          v-if="day > 0"
                          class="cursor-pointer"
                          @click="getTasksFiltered(iDayIndex, null, item)"
                          :key="iDayIndex"
                        >
                          {{
                            item["dia_" + iDayIndex]
                              ? item["dia_" + iDayIndex]["cantidad"]
                              : ""
                          }}
                        </span>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </v-col>
      <v-col cols="12" v-if="dialogShowCampaign">
        <div class="card card-custom card-stretch">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Proyección</h3>
            </div>
            <v-col cols="5" class="float-right" style="padding-top: 1rem">
              <v-autocomplete
                v-model="jobber"
                default=""
                :items="jobbers"
                item-key="jobberID"
                item-text="jobberNombreCompleto"
                return-object
                @change="filterProjection"
                label="Jobber"
                dense
              ></v-autocomplete>
            </v-col>
          </div>
          <div class="card-body p-5 pb-7">
            <b-tabs content-class="mt-3">
              <b-tab title="TABLA" active>
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      color="primary float-right"
                      dark
                      class="mb-2"
                      v-on:click="
                        downloadExcelFile(
                          'proyeccion',
                          'projection_table',
                          headersProjection.length
                        )
                      "
                    >
                      Exportar
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-data-table
                      :headers="headersProjection"
                      :items="projectionInfoFiltered"
                      class="table-striped elevation-1"
                      hide-default-footer
                      disable-pagination
                      id="projection_table"
                    >
                      <template v-slot:item="row">
                        <tr class="text-center">
                          <td>{{ row.item.dias }}</td>
                          <td>{{ row.item.fecha }}</td>
                          <td>{{ row.item.dia }}</td>
                          <td>{{ row.item.metaMes }}</td>
                          <td>{{ row.item.metaDia }}</td>
                          <td>{{ row.item.metaAcum }}</td>
                          <td :class="{ 'bg-warning': row.item.predict }">
                            {{ row.item.real }}
                          </td>
                          <td :class="{ 'bg-warning': row.item.predict }">
                            {{ row.item.realAcum }}
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </b-tab>
              <b-tab title="GRAFICO">
                <canvas id="projection_chart"></canvas>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </v-col>
    </v-row>
    <TaskListDialog ref="TaskListDialog"></TaskListDialog>
  </div>
</template>

<script>
import { _ } from "vue-underscore";
import draggable from "vuedraggable";
import moment from "moment";
import "moment/locale/es";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import {
  GET_CAMPAIGNS_MANDANTE,
  GET_ALL_CAMPAIGN_DATA,
  GET_CAMPAIGN_JOBS_FINISHED_CAMPAIGN_DATA,
  GET_ESTADOS_CAMPAIGN,
} from "@/core/services/store/campaign.module";
import { GET_MANDANTES } from "@/core/services/store/mandante.module";
import { GET_TASK_TRACING_CAMPAIGN_DATA } from "@/core/services/store/tracing.module";
import { GET_TASK_CAMPAIGN } from "@/core/services/store/task.module";
import { mapGetters } from "vuex";
import XLSX from "sheetjs-style";
import Swal from "sweetalert2";
import { Chart } from "chart.js";
import CampaignDetail from "../components/campaign/CampaignDetail.vue";
import TaskListDialog from "../components/task/TaskListDialog.vue";
import { getInfoXLSXCampaign } from "../../helpers/helper";
export default {
  name: "task",
  components: {
    //eslint-disable-next-line
    draggable,
    CampaignDetail,
    TaskListDialog,
  },
  data() {
    return {
      params: null,
      dialogShowCampaign: false,
      headersTasks: [],
      tasks: [],
      headersFields: [],
      fields: [],
      campaign: null,
      campaigns: [],
      campaignsFiltered: [],
      estado: null,
      estados: [],
      jobber: null,
      jobbers: [],
      mandante: null,
      mandantes: [],
      search: "",
      campaignData: {
        campaignID: null,
        nombre: "",
        estado: "",
        fechaInicio: "",
        fechaTermino: "",
        cantidadTareasAsignadas: 0,
        cantidadTareasRealizadas: 0,
        tareas: [],
      },
      totalTasksDays: [],
      totalFieldsDays: [],
      dialogFile: false,
      fileBase64: null,
      headersProjection: [
        {
          text: "Dias",
          align: "center",
          value: "dias",
          sortable: false,
        },
        {
          text: "Fecha",
          align: "center",
          value: "fecha",
          sortable: false,
        },
        {
          text: "Dia",
          value: "dia",
          align: "center",
          width: "11em",
          sortable: false,
        },
        {
          text: "Meta Mes",
          value: "metaMes",
          align: "center",
          sortable: false,
        },
        {
          text: "Meta del dia",
          value: "metaDia",
          align: "center",
          sortable: false,
        },
        {
          text: "Meta Acum",
          value: "metaAcum",
          align: "center",
          sortable: false,
        },
        {
          text: "Real",
          value: "real",
          align: "center",
          sortable: false,
        },
        {
          text: "Real Acum",
          value: "realAcum",
          align: "center",
          sortable: false,
        },
      ],
      projectionInfo: [],
      projectionInfoFiltered: [],
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            label: "Meta Mes",
            borderColor: "#3e95cd",
            fill: false,
          },
          {
            data: [],
            label: "Meta del dia",
            borderColor: "#8e5ea2",
            fill: false,
          },
          {
            data: [],
            label: "Meta Acum",
            borderColor: "#3cba9f",
            fill: false,
          },
          {
            data: [],
            label: "Real",
            borderColor: "#e8c3b9",
            fill: false,
          },
          {
            data: [],
            label: "Real Acum",
            borderColor: "#c45850",
            fill: false,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      jobsRealized: [],
      chartDataFiltered: [],
      chart: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Seguimiento", route: "tracing" },
    ]);

    let oVue = this;
    const params = oVue.$route.params;
    if (params.mandante || params.campaign) {
      oVue.params = params;
      KTUtil.setLoading();
    }
    if (
      params.mandante ||
      Number(oVue.currentUserPersonalInfo.perfil_id) === 2
    ) {
      if (Number(oVue.currentUserPersonalInfo.perfil_id) === 2) {
        oVue.mandante = oVue.currentUserPersonalInfo.mandante;
      } else {
        oVue.mandante = params.mandante;
      }
      oVue.getCampaigns();
    }
    if (params.campaign) {
      oVue.campaign = params.campaign;
      oVue.showCampaign();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getMandantes();
      this.getEstados();
      // this.campaign = 93;
      // this.showCampaign();
    },
    getMandantes() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.mandantes = [];
      oVue.$store.dispatch(GET_MANDANTES).then((mandantes) => {
        let array = [];
        mandantes.forEach((mandante) => {
          array.push(mandante.mandanteNombre);
        });
        oVue.mandantes = array;
        if (!oVue.params) {
          KTUtil.removeLoading();
        }
      });
    },
    getEstados() {
      let oVue = this;
      oVue.campaigns = [];
      KTUtil.setLoading();
      oVue.$store.dispatch(GET_ESTADOS_CAMPAIGN).then((estados) => {
        estados.unshift({ estadoID: "", estadoDescripcion: "" });
        oVue.estados = estados;
        if (!oVue.params) {
          KTUtil.removeLoading();
        }
      });
    },
    getCampaigns() {
      let oVue = this;
      oVue.campaigns = [];
      if (oVue.mandante) {
        KTUtil.setLoading();
        oVue.$store
          .dispatch(GET_CAMPAIGNS_MANDANTE, oVue.mandante)
          .then((campaigns) => {
            oVue.campaigns = oVue.campaignsFiltered = campaigns;
            if (!oVue.params) {
              KTUtil.removeLoading();
            }
          });
      }
    },
    filterCampaigns() {
      let oVue = this;
      if (!oVue.estado) {
        oVue.campaignsFiltered = oVue.campaigns;
      } else {
        oVue.campaignsFiltered = oVue.campaigns.filter((c) => {
          if (oVue.estado != 5) {
            return c.estadoID == oVue.estado;
          } else {
            return moment() > moment(c.fechaTermino, "DD-MM-YYYY");
          }
        });
      }
    },
    async showCampaign() {
      let oVue = this;
      oVue.dialogShowCampaign = false;

      if (!oVue.campaign) {
        Swal.fire({
          title: "Error!",
          text: "Debe seleccionar una campaña",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });
        return;
      }

      oVue.chartData.labels = [];

      oVue.chartData.datasets[0].data = [];
      oVue.chartData.datasets[1].data = [];
      oVue.chartData.datasets[2].data = [];
      oVue.chartData.datasets[3].data = [];
      oVue.chartData.datasets[4].data = [];

      const aAllCampaignDataResponse = await oVue.getAllCampaignData(
        oVue.campaign
      );
      if (aAllCampaignDataResponse.result) {
        var campaignData = aAllCampaignDataResponse.data.general;
        oVue.campaignData = campaignData;

        const aAllTaskDataResponse = await oVue.getTasksByCampaign(
          campaignData.campaignID
        );
        const taskData = aAllTaskDataResponse;
        oVue.campaignData.tareas = taskData;

        const aAllTracingDataResponse = await oVue.getTasksTracingByCampaign(
          campaignData.campaignID
        );
        oVue.tasks = aAllTracingDataResponse;

        let jobbers = [];
        jobbers.unshift({ jobberID: "", jobberNombreCompleto: "" });
        aAllTracingDataResponse.forEach((jobber) => {
          jobbers.push(jobber);
        });
        oVue.jobbers = jobbers;

        const aFields = _.filter(aAllCampaignDataResponse.data.fields, {
          origen: "CONFIG",
        });
        const aOptions = aAllCampaignDataResponse.data.options;
        let fields = [];
        aFields.forEach((f) => {
          f.isOption = 0;
          fields.push(f);
          if (f.hasOptions) {
            let options = _.filter(aOptions, { idCampo: f.idCampo });
            options.forEach((o) => {
              fields.push({
                idCampo: o.idCampo,
                idOpcion: o.idOpcion,
                titulo: o.opcion,
                isOption: 1,
              });
            });
          }
        });
        oVue.fields = fields;
        const aAllJobsRealizedDataResponse =
          await oVue.getCampaignJobsRealizedByCampaign(campaignData.campaignID);
        const aAllJobsRealized =
          typeof aAllJobsRealizedDataResponse !== "object"
            ? JSON.parse(aAllJobsRealizedDataResponse)
            : aAllJobsRealizedDataResponse;
        oVue.jobsRealized = aAllJobsRealized;
        oVue.headersTasks = [
          {
            text: "Jobber",
            align: "start",
            value: "jobberNombre",
            width: "15em",
            sortable: false,
          },
          { text: "Tareas", value: "tareas", align: "center", sortable: false },
        ];
        oVue.headersFields = [
          {
            text: "Campo",
            align: "start",
            value: "titulo",
            width: "15em",
          },
          { text: "Total", value: "tareas", align: "center", sortable: false },
        ];

        oVue.fields.forEach((f, i) => {
          oVue.fields[i]["totalTareasRealizadas"] = 0;
          oVue.fields[i]["totalTareas"] = 0;
        });
        const aFi = campaignData.fechaInicio.split("-");
        var oFi = moment([aFi[2], aFi[1] - 1, aFi[0]]);
        const aFf = campaignData.fechaTermino.split("-");
        const oFf = moment([aFf[2], aFf[1] - 1, aFf[0]]);
        const oFh = moment();
        const totalDays = oFf.diff(oFi, "days") + 1;
        var totalTasksDays = [];
        var totalFieldsDays = [];
        const metaMes = campaignData.cantidadTareas;
        const metaDia = oVue.precise_round(
          campaignData.cantidadTareas / campaignData.diasTotales,
          2
        );
        let metaAcum = 0;
        let real = 0;
        let realAcum = 0;
        let metaPredic = 0;
        let predict = false;
        let index = 0;
        oVue.projectionInfo = [];
        while (oFi <= oFf) {
          var totalTasksDay = 0;
          var fieldRealizedDays = 0;
          let day = oFi.date();
          let month = oFi.month() + 1;
          let year = oFi.year();
          let dia_semana = oFi.format("dddd").substr(0, 3);
          let dias = index + 1;
          if (day < 10) {
            day = "0" + day;
          }
          if (month < 10) {
            month = "0" + month;
          }
          var fulldate = `${day}-${month}-${year}`;
          let indexDay = "dia_" + index;
          const aJobsRealized = aAllJobsRealized.filter(
            (aJob) => aJob.managmentDate == fulldate
          );
          aJobsRealized.forEach((j) => {
            const taskIndex = oVue.tasks.findIndex(
              (x) => x.jobberID === j.jobberID
            );
            if (typeof oVue.tasks[taskIndex][indexDay] !== "undefined") {
              oVue.tasks[taskIndex][indexDay]["cantidad"]++;
              oVue.tasks[taskIndex][indexDay]["tasks"].push(j.jobID);
            } else {
              oVue.tasks[taskIndex][indexDay] = [];
              oVue.tasks[taskIndex][indexDay]["cantidad"] = 1;
              oVue.tasks[taskIndex][indexDay]["tasks"] = [j.jobID];
            }
            oVue.fields.forEach((f, i) => {
              let finded = false;
              let indexField = "campo_" + f.idCampo;
              const aAtributos =
                typeof j.atributos !== "object"
                  ? JSON.parse(j.atributos)
                  : j.atributos;
              let aAtributo = aAtributos[indexField];
              if (aAtributo) {
                if (!f.isOption) {
                  if (aAtributo.value != "") {
                    finded = true;
                  }
                } else {
                  if (aAtributo.value == f.titulo) {
                    finded = true;
                  }
                }
                if (finded) {
                  if (typeof oVue.fields[i][indexDay] !== "undefined") {
                    oVue.fields[i][indexDay]["cantidad"]++;
                    oVue.fields[i][indexDay]["tasks"].push(j.jobID);
                  } else {
                    oVue.fields[i][indexDay] = [];
                    oVue.fields[i][indexDay]["cantidad"] = 1;
                    oVue.fields[i][indexDay]["tasks"] = [j.jobID];
                  }
                  fieldRealizedDays++;
                  oVue.fields[i]["totalTareasRealizadas"]++;
                }
              }
              oVue.fields[i]["totalTareas"]++;
            });
            totalTasksDay++;
          });
          const daymonth = oFi.format("DD-MM");
          const text = `${dia_semana} ${daymonth}`;
          const column = {
            text,
            value: indexDay,
            align: "center",
            sortable: false,
            width: "1px",
          };
          oVue.headersTasks.push(column);
          oVue.headersFields.push(column);
          totalTasksDays[index] = totalTasksDay;
          totalFieldsDays[index] = fieldRealizedDays;

          if (oFi <= oFh) {
            real = totalTasksDay;
            predict = false;
          } else {
            if (metaPredic == 0) {
              metaPredic = oVue.precise_round(realAcum / dias, 2);
            }
            real = metaPredic;
            predict = true;
          }

          metaAcum = oVue.precise_round(metaAcum + metaDia, 2);
          realAcum = oVue.precise_round(realAcum + real, 2);
          if (index == totalDays - 1) {
            metaAcum = metaMes;
          }

          let projectionInfo = {};

          projectionInfo.dias = dias;
          projectionInfo.fecha = fulldate;
          projectionInfo.dia = dia_semana;

          projectionInfo.metaMes = metaMes;
          projectionInfo.metaDia = metaDia;
          projectionInfo.metaAcum = metaAcum;
          projectionInfo.real = real;
          projectionInfo.realAcum = realAcum;
          projectionInfo.predict = predict;
          oVue.projectionInfo.push(projectionInfo);

          let aLabels = [dias, fulldate, dia_semana];

          oVue.chartData.labels.push(aLabels);

          oVue.chartData.datasets[0].data.push(metaMes);
          oVue.chartData.datasets[1].data.push(metaDia);
          oVue.chartData.datasets[2].data.push(metaAcum);
          oVue.chartData.datasets[3].data.push(real);
          oVue.chartData.datasets[4].data.push(realAcum);

          oFi.add(1, "day");
          index++;
        }
        oVue.projectionInfoFiltered = JSON.parse(
          JSON.stringify(oVue.projectionInfo)
        );
        oVue.chartDataFiltered = JSON.parse(JSON.stringify(oVue.chartData));
        oVue.totalTasksDays = totalTasksDays;
        oVue.totalFieldsDays = totalFieldsDays;
        oVue.dialogShowCampaign = true;
        setTimeout(() => {
          oVue.initializeChart();
        }, 100);
      }
    },
    async getAllCampaignData(sCampaignNombre) {
      KTUtil.setLoading();
      let oVue = this;
      const aAllCampaignDataResponse = await oVue.$store.dispatch(
        GET_ALL_CAMPAIGN_DATA,
        { campaign: sCampaignNombre }
      );
      KTUtil.removeLoading();

      return aAllCampaignDataResponse;
    },
    async getTasksTracingByCampaign(iCampaignID) {
      KTUtil.setLoading();
      let oVue = this;
      const aAllTaskDataResponse = await oVue.$store.dispatch(
        GET_TASK_TRACING_CAMPAIGN_DATA,
        iCampaignID
      );
      KTUtil.removeLoading();

      return aAllTaskDataResponse;
    },
    async getCampaignJobsRealizedByCampaign(iCampaignID) {
      KTUtil.setLoading();
      let oVue = this;
      const aAllJobsDataResponse = await oVue.$store.dispatch(
        GET_CAMPAIGN_JOBS_FINISHED_CAMPAIGN_DATA,
        { campaign: iCampaignID }
      );
      KTUtil.removeLoading();

      return aAllJobsDataResponse;
    },
    async getTasksByCampaign(iCampaignID) {
      KTUtil.setLoading();
      let oVue = this;
      const aAllJobsDataResponse = await oVue.$store.dispatch(
        GET_TASK_CAMPAIGN,
        iCampaignID
      );
      KTUtil.removeLoading();

      return aAllJobsDataResponse;
    },
    downloadExcelFile(filename, id, cols) {
      let oVue = this;
      const wb = (XLSX.WorkBook = XLSX.utils.book_new());

      //Datos de la campaña
      const infoXLSXCampaign = getInfoXLSXCampaign(oVue.campaignData);
      const ws_campaign_name = "campaña";
      const ws_campaign = XLSX.utils.aoa_to_sheet(infoXLSXCampaign);
      wb.SheetNames.push(ws_campaign_name);
      wb.Sheets[ws_campaign_name] = ws_campaign;

      //Datos personalizados
      const elt = KTUtil.getById(id);
      const table = elt.querySelector("table");
      const ws = (XLSX.WorkSheet = XLSX.utils.table_to_sheet(table, {
        raw: true,
      }));

      let row = 0;
      for (let col = 0; col <= cols; col++) {
        const ref = XLSX.utils.encode_cell({ r: row, c: col });
        if (ws[ref]) {
          ws[ref].s = {
            font: {
              bold: true,
            },
          };
        }
      }

      if (id == "field_table") {
        let rows = oVue.fields.length;
        let col = 0;
        for (let row = 1; row <= rows; row++) {
          const ref = XLSX.utils.encode_cell({ r: row, c: col });
          if (ws[ref]) {
            let oField = _.findWhere(oVue.fields, { titulo: ws[ref].v });
            if (oField) {
              if (oField.isOption == 1) {
                ws[ref].v = "     " + ws[ref].v;
                ws[ref].s = {
                  font: {
                    italic: true,
                  },
                };
              } else {
                ws[ref].s = {
                  font: {
                    bold: true,
                  },
                };
              }
            }
          }
        }
      }

      if (id == "projection_table") {
        let metaPredic = _.findWhere(oVue.projectionInfoFiltered, {
          predict: true,
        }).real;
        if (metaPredic) {
          let rows = oVue.projectionInfoFiltered.length;
          let fill = false;
          for (let row = 1; row <= rows; row++) {
            for (let col = 6; col <= 7; col++) {
              const ref = XLSX.utils.encode_cell({ r: row, c: col });
              if (ws[ref]) {
                if (!fill && metaPredic == ws[ref].v) {
                  fill = true;
                }
                if (fill) {
                  ws[ref].s = {
                    fill: {
                      fgColor: { rgb: "ff6600" },
                    },
                  };
                }
              }
            }
          }
        }
      }

      XLSX.utils.book_append_sheet(wb, ws, filename);

      return XLSX.writeFile(wb, `${filename}.xlsx`);
    },
    getTasksFiltered(indexDay, jobber, oField) {
      let oVue = this;
      let params = { campaign: oVue.campaignData.campaignID };
      if (indexDay >= 0) {
        const oDay = oVue.projectionInfo[indexDay];
        const fechaGestionOriginal = moment(oDay.fecha, "DD-MM-YYYY").format(
          "YYYY-MM-DD"
        );
        params.fechaGestionOriginal = fechaGestionOriginal;
      }
      if (oField) {
        let aTasks = [];
        if (indexDay >= 0) {
          aTasks = oField["dia_" + indexDay]["tasks"];
        } else {
          Object.entries(oField).forEach((aField) => {
            if (aField[0].includes("dia")) {
              aTasks.push(...aField[1].tasks);
            }
          });
        }
        if (aTasks.length > 0) {
          params.tasks = aTasks.join(",");
        }
      }
      if (jobber) {
        params.jobber = jobber;
      }
      params.estado = 3;
      oVue.$refs.TaskListDialog.getTasks(params);
    },
    precise_round(num, decimal) {
      return parseFloat((Math.round((num * 1000) / 10) / 100).toFixed(decimal));
    },
    filterProjection() {
      let oVue = this;
      if (oVue.jobber && oVue.jobber.jobberID != "") {
        oVue.chartDataFiltered.datasets[0].data = [];
        oVue.chartDataFiltered.datasets[1].data = [];
        oVue.chartDataFiltered.datasets[2].data = [];
        oVue.chartDataFiltered.datasets[3].data = [];
        oVue.chartDataFiltered.datasets[4].data = [];

        const metaMes = oVue.jobber.cantidadTareasAsignadas;
        const metaDia = oVue.precise_round(
          metaMes / oVue.campaignData.diasTotales,
          2
        );

        let metaAcum = 0;
        let real = 0;
        let realAcum = 0;
        let metaPredic = 0;

        let totalDays = oVue.projectionInfoFiltered.length;

        oVue.projectionInfoFiltered.forEach((day) => {
          if (!day.predict) {
            const aJobsRealizedCount = oVue.jobsRealized.filter(
              (aJob) =>
                aJob.managmentDate == day.fecha &&
                aJob.jobberID == oVue.jobber.jobberID
            ).length;
            real = aJobsRealizedCount;
          } else {
            if (metaPredic == 0) {
              metaPredic = oVue.precise_round(realAcum / day.dias, 2);
            }
            real = metaPredic;
          }

          metaAcum = oVue.precise_round(metaAcum + metaDia, 2);
          realAcum = oVue.precise_round(realAcum + real, 2);

          if (day.dias == totalDays) {
            metaAcum = metaMes;
          }

          day.metaMes = metaMes;
          day.metaDia = metaDia;
          day.metaAcum = metaAcum;
          day.real = real;
          day.realAcum = realAcum;

          oVue.chartDataFiltered.datasets[0].data.push(metaMes);
          oVue.chartDataFiltered.datasets[1].data.push(metaDia);
          oVue.chartDataFiltered.datasets[2].data.push(metaAcum);
          oVue.chartDataFiltered.datasets[3].data.push(real);
          oVue.chartDataFiltered.datasets[4].data.push(realAcum);
        });
      } else {
        oVue.projectionInfoFiltered = JSON.parse(
          JSON.stringify(oVue.projectionInfo)
        );
        oVue.chartDataFiltered = JSON.parse(JSON.stringify(oVue.chartData));
      }
      oVue.initializeChart();
    },
    initializeChart() {
      let oVue = this;
      if (oVue.chart != null) {
        oVue.chart.destroy();
      }
      oVue.chart = new Chart(KTUtil.getById("projection_chart"), {
        type: "line",
        data: oVue.chartDataFiltered,
      });
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
};
</script>
